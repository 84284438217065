import { GeoTool } from './GeoTool';

class GeoWidget extends GeoTool {

    constructor(config) {

        super(config);

        this._elementId = '';
        this._contentId = '';
        this._headerId = '';
        this._titleId = '';
        this._closeId = '';
        this._controlElement = null;
        this._onDock = false;
        this.__elements = {
            widget: null,
            header: null,
            content: null,
            close: null,
            dock: null,
            maximize: null,
            resizer: null
        };

    }

    get hasUI() {
        return this._hasUI;
    }

    get controlElement() {
        return this._controlElement;
    }

    set controlElement(element) {
        this._controlElement = element;
    }

    get onDock() {
        return this._onDock;
    }

    createWidget() {

        this.__handleUiCreation();

        // Cria elemento externo widget
        this.__createWidget();

        if (!this.config.untitled) {

            this.__createWidgetHeader();
            this.__createWidgetTitle();
            this.__createWidgetClose();

            if (this.config.docked) {

                this.__createWidgetDock();

            }

        }

        this.__createWidgetContent();

        this.__makeDraggable();
        this.__makeResizable();

        if (this.config.maximized) {
            this.resizeToMax();
        }

    }

    _setWidgetMinMaxSizes() {

        let maxHeight = this.map.mapElement.clientHeight * 0.95;
        let maxWidth = this.map.mapElement.clientWidth * 0.95;

        if (this.config.minHeight) {
            this.__elements.widget.style.minHeight = this.config.minHeight;
            this.__elements.widget.style.height = this.__elements.widget.style.height || this.config.minHeight;
        }
        if (this.config.minWidth) {
            this.__elements.widget.style.minWidth = this.config.minWidth;
            this.__elements.widget.style.width = this.__elements.widget.style.width || this.config.minWidth;
        }

        this.__elements.widget.style.maxHeight = this.config.maxHeight || maxHeight + 'px';
        this.__elements.widget.style.maxWidth = this.config.maxWidth || maxWidth + 'px';

    }

    __createWidget() {

        this.__elements.widget = document.createElement('div');
        this.__elements.widget.id = `gb_c${this.id}_${this.map.target}`;
        this.__elements.widget.className = 'gb gb-widget gb-widget-resizable';



    }

    __createWidgetContent() {

        this.__elements.container = document.createElement('div');
        this.__elements.container.className = 'gb-widget-container';

        this.__elements.content = document.createElement('div');
        this.__elements.content.id = this._contentId;
        this.__elements.content.className = 'gb-widget-content';

        let resizersElement = document.createElement('div');
        resizersElement.className = 'gb-widget-resizers';

        this.__elements.resizer = document.createElement('div');
        this.__elements.resizer.className = 'gb-widget-resizer gb-widget-bottom-right';

        resizersElement.append(this.__elements.resizer);
        this.__elements.widget.append(resizersElement);

        this.__elements.content.append(this.ui);
        this.__elements.container.append(this.__elements.content);
        this.__elements.widget.append(this.__elements.container);

        document.getElementById(this.map.elementId).append(this.__elements.widget);

        this._setWidgetMinMaxSizes();


    }

    __createWidgetHeader() {

        this.__elements.header = document.createElement('div');
        this.__elements.header.id = this._headerId;
        this.__elements.header.className = 'gb-widget-header';

        this.__elements.widget.append(this.__elements.header);

    }

    __createWidgetTitle() {

        this.__elements.title = document.createElement('span');
        this.__elements.title.id = this._titleId;
        this.__elements.title.innerHTML = this._config.title;

        this.__elements.header.append(this.__elements.title);

    }

    __createWidgetClose() {

        this.__elements.close = document.createElement('div');
        this.__elements.close.id = this._closeId;
        this.__elements.close.className = 'gb-widget-close';
        this.__elements.close.innerHTML = '  ×';

        this.__elements.header.append(this.__elements.close);

        this.__elements.close.addEventListener('click', () => {

            this.deactivate();

        });

    }

    __createWidgetDock() {

        this.__elements.dock = document.createElement('div');
        this.__elements.dock.id = this._dockId;
        this.__elements.dock.className = 'gb-widget-close';
        this.__elements.dock.innerHTML = '&#8718;';

        this.__elements.header.append(this.__elements.dock);

        this.__elements.dock.addEventListener('click', () => {

            if (this._onDock) {

                this.removeFromDock('right');

            } else {

                this.moveToDock('right');

            }

        });

    }

    __handleUiCreation() {

        if (!this.ui) {
            throw 'Erro ao criar widget: "ui" não definda';
        }

        if (typeof this.ui === 'string') {
            let uiElement = document.createElement('div');
            uiElement.style.width = '100%';
            uiElement.innerHTML = this.ui;
            this.ui = uiElement;
        } else if (typeof this.ui !== 'object') {
            throw 'Erro ao criar widget: "ui" deve ser um elemento HTML válido';
        }

    }

    hide() {

        this.__elements.widget.style.display = 'none';
        this._isOpen = false;
        this._isActive = false;
        this._controlElement.classList.remove('gb-control-active');

        if (this._onDock) {
            this.removeFromDock(this.config.dockPosition || 'right');
        }

        let widgetElement = this.__elements.widget;
        widgetElement.classList.remove('gb-widget-docked');
        widgetElement.classList.remove('gb-widget-maximized');

    }

    show() {

        this.__elements.widget.style.display = 'block';
        this.__elements.widget.style.zIndex = ++GeoWidget.currentZIndex;

        this._isOpen = true;
        this._isActive = true;
        this._controlElement.classList.add('gb-control-active');

        if (this._config.docked) {
            this.moveToDock(this._config.dockPosition || 'right');
        }

        this.__setWidgetSize();

    }

    __setWidgetSize() {

        let content_height = parseFloat(getComputedStyle(this.__elements.content, null).getPropertyValue('height').replace('px', ''));
        let header_height = parseFloat(getComputedStyle(this.__elements.header, null).getPropertyValue('height').replace('px', ''));
        this.__elements.widget.style.height = this.__elements.widget.style.height || (content_height + header_height) * 1.05 + 'px';
        let content_width = parseFloat(getComputedStyle(this.__elements.content, null).getPropertyValue('width').replace('px', ''));
        this.__elements.widget.style.width = this.__elements.widget.style.width || content_width + 'px';

    }

    /* https://www.w3schools.com/howto/howto_js_draggable.asp */
    __makeDraggable() {

        let self = this;
        let element = this.__elements.widget;
        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

        if (this.__elements.header) {
            // if present, the header is where you move the DIV from:
            this.__elements.header.onmousedown = dragMouseDown;
        } else {
            // otherwise, move the DIV from anywhere inside the DIV: 
            element.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            element.style.top = (element.offsetTop - pos2) + 'px';
            element.style.left = (element.offsetLeft - pos1) + 'px';
            element.style.opacity = 0.5;
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
            element.style.opacity = 1;
            let mapRect = self.map.mapElement.getBoundingClientRect();
            let widgetRect = self.__elements.widget.getBoundingClientRect();

            // top
            if ((widgetRect.top - mapRect.top) < 0) {
                self.__elements.widget.style.top = '10px';
            }
            // left
            if ((widgetRect.left - mapRect.left) < 0) {
                self.__elements.widget.style.left = '10px';
            }
            // Right
            if ((widgetRect.right - mapRect.right) > 0) {
                self.__elements.widget.style.left = '10px';
            }
            // Bottom
            if ((widgetRect.bottom - mapRect.bottom) > 0) {
                self.__elements.widget.style.top = '10px';
            }
        }
    }

    /*Make resizable div by Hung Nguyen*/
    __makeResizable() {

        let self = this;
        let element = this.__elements.widget;
        let resizer = this.__elements.resizer;
        let original_width = 0;
        let original_height = 0;
        let original_mouse_x = 0;
        let original_mouse_y = 0;
        let original_x = 0;
        let original_y = 0;



        resizer.addEventListener('mousedown', function (e) {
            e.preventDefault();
            original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
            original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
            original_x = element.getBoundingClientRect().left;
            original_y = element.getBoundingClientRect().top;
            original_mouse_x = e.pageX;
            original_mouse_y = e.pageY;

            window.addEventListener('mousemove', resize);
            window.addEventListener('mouseup', () => stopResize());

        });


        function resize(e) {
            if (resizer.classList.contains('gb-widget-bottom-right')) {
                element.style.width = original_width + (e.pageX - original_mouse_x) + 'px';
                element.style.height = original_height + (e.pageY - original_mouse_y) + 'px';
            } else {
                stopResize();
            }
        }

        function stopResize() {

            window.removeEventListener('mousemove', resize);
            var mapRect = self.map.mapElement.getBoundingClientRect();
            var widgetRect = self.__elements.widget.getBoundingClientRect();

            // top
            if ((widgetRect.top - mapRect.top) < 0) {
                self.__elements.widget.style.top = '10px';
            }
            // left
            if ((widgetRect.left - mapRect.left) < 0) {
                self.__elements.widget.style.left = '10px';
            }
            // Right
            if ((widgetRect.right - mapRect.right) > 0) {
                self.__elements.widget.style.left = mapRect.right - widgetRect.width - 10 + 'px';
            }
            // Bottom
            if ((widgetRect.bottom - mapRect.bottom) > 0) {
                self.__elements.widget.style.top = mapRect.bottom - widgetRect.height - 10 + 'px';
            }

        }

    }

    resizeToMax() {

        let element = this.__elements.widget;
        element.classList.add('gb-widget-maximized');

    }

    moveToDock(position) {

        this.map.unDockWidgetFrom(position);

        let dockId = `gb-dock-${position}_${this.map.target}`;
        let dockElement = document.getElementById(dockId);
        dockElement.appendChild(this.__elements.widget);
        this.__elements.widget.classList.add('gb-widget-docked');
        this.map.openDock(position);
        this._onDock = position;

        if (this.__elements.header) {
            this.__elements.header.style.cursor = 'auto';
            this.__elements.header.onmousedown = null;
        }

    }

    removeFromDock(position) {

        let mapElement = document.getElementById(this.map.elementId);
        let widgetElement = this.__elements.widget;
        mapElement.appendChild(widgetElement);
        widgetElement.classList.remove('gb-widget-docked');
        this.__makeDraggable();
        this.map.closeDock(position);
        this._onDock = null;

        if (this.__elements.header) {
            this.__elements.header.style.cursor = 'move';
        }

    }

    moveTo(x, y) {

        let unit = 'px';
        let mapElement = document.getElementById(this.map.elementId);
        let widgetWidth = parseFloat(getComputedStyle(this.__elements.widget, null).getPropertyValue('width').replace('px', ''));
        let widgetHeight = parseFloat(getComputedStyle(this.__elements.widget, null).getPropertyValue('height').replace('px', ''));
        let mapWidth = parseFloat(getComputedStyle(mapElement, null).getPropertyValue('width').replace('px', ''));
        let mapHeight = parseFloat(getComputedStyle(mapElement, null).getPropertyValue('height').replace('px', ''));
        let v = y;
        if ((widgetWidth + x) > mapWidth) {

            this.__elements.widget.style.left = (x - widgetWidth) + unit;

        } else {

            this.__elements.widget.style.left = x + unit;

        }

        if ((widgetHeight + y) > mapHeight) {

            v = (y - widgetHeight);

        }

        if (v < 0) v = 0;

        this.__elements.widget.style.top = v + unit;

    }


}

GeoWidget.currentZIndex = 9000;

export { GeoWidget };