class GeoMap {

    constructor(target, config) {

        this._config = config;
        this._target = target || 'map';
        this._elementId = `${target}_content` || 'map_content';
        this._content = null;
        this._thematicLayers = null;
        this._mapSrid = config.SRID || 'EPSG:3857';
        this._displaySrid = config.displaySrid || 'EPSG:4326';
        this._buttons = [];
        this._tools = [];
        this._panels = [];
        this._subPanels = [];
        this._docks = [];
        this._widgets = [];
        this.toolbox = {};

        this._externalTools = {};
        this._events = {
            ready: [],
            draw: [],
            removeLayers: []
        };
        this._defaultStyle = null;
        this._dockIsOpen = {
            left: false,
            right: false,
            bottom: false
        };

        this._panelPosition = {
            LEFT_TOP: 'lt',
            LEFT_CENTER: 'lc',
            LEFT_BOTTOM: 'lb',
            RIGHT_TOP: 'rt',
            RIGHT_CENTER: 'rc',
            RIGHT_BOTTOM: 'rb',
            CENTER_TOP: 'ct',
            CENTER_CENTER: 'cc',
            CENTER_BOTTOM: 'cb'
        };

        this.containerElement = null;
        this.mapElement = null;

        this._graphicsLayer = null;

        this._setDefaultConfig();
        this._createContentSchema();
        this._createGraphicsLayer();
        this._initMap();
        this._createLayers();
        this._drawLayers();
        this._registerEvents();
        this._saveDefaultStyle();
    }

    get ol() {
        return this._map2D;
    }

    get elementId() {
        return this._elementId;
    }

    get subPanels() {
        return this._subPanels;
    }

    set subPanels(subPanels) {
        this._subPanels = subPanels;
    }

    get target() {
        return this._target;
    }

    get clientSize() {
        return this.mapElement.clientWidth;
    }

    get clientWidth() {
        return this.mapElement.clientWidth;
    }

    get clientHeight() {
        return this.mapElement.clientHeight;
    }

    get srid() {
        return this._mapSrid;
    }

    get content() {
        return this._content;
    }

    set content(content) {
        this._content = content;
    }

    get toolbox() {
        return this._externalTools;
    }

    set toolbox(tool) {
        this._externalTools = tool;
    }

    get displaySrid() {
        return this._displaySrid;
    }

    set displaySrid(srid) {
        this._displaySrid = srid;
    }

    get config() {
        return this._config;
    }

    set config(conf) {
        this._config = conf;
    }

    _setDefaultConfig() {

        this._content = this._config.content;
        this._thematicLayers = this._config.thematicLayers || {};
        this._config.initialZoom = this._config.initialZoom || 10;
        this._config.minZoom = this._config.minZoom || 1;
        this._config.maxZoom = this._config.maxZoom || 22;

        this._config.linearUnits = this._config.linearUnits || 'm';
        this._config.areaUnits = this._config.areaUnits || 'm2';
        this._config.angularUnits = this._config.angularUnits || 'dms';
        this._config.precision = this._config.precision || 3;
        this._config.displaySrid = this._config.displaySrid || 'EPSG:4326';

    }

    _initMap() {

        this._registerProjections();

        let center = [0, 0];
        if (this._config.center && this._config.center.srid != this._mapSrid) {
            center = ol.proj.transform(this._config.center.coordinates, this._config.center.srid || 'EPSG:4326', this._mapSrid);
        }

        this._map2D = new ol.Map({
            target: this._elementId,
            view: new ol.View({
                projection: ol.proj.get(this._mapSrid),
                center: center,
                zoom: this._config.initialZoom,
                minZoom: this._config.minZoom,
                maxZoom: this._config.maxZoom
            }),
            layers: [this._graphicsLayer],
            controls: ol.control.defaults({
                attribution: false,
                zoom: false
            })
        });

        this._map2D.on('change:size', () => {
            for (let i = 0; i < this._tools.length; i++) {
                if (this._tools[i].ui) {
                    this._tools[i]._setWidgetMinMaxSizes();
                }
            }
        });

    }

    _registerProjections() {

        if (proj4) {

            let projections = this._config.srs;

            for (let i = 0; i < projections.length; i++) {
                const p = projections[i];
                proj4.defs(p.code, p.defs);
            }

            ol.proj.proj4.register(proj4);
        }

    }

    _createGraphicsLayer() {

        this._graphicsLayer = new ol.layer.Vector({
            source: new ol.source.Vector(),
            style: new ol.style.Style({
                fill: new ol.style.Fill({
                    color: 'rgba(255, 0, 0, 0.5)'
                }),
                stroke: new ol.style.Stroke({
                    color: 'red',
                    width: 2
                }),
                image: new ol.style.Circle({
                    radius: 7,
                    fill: new ol.style.Fill({
                        color: 'red'
                    })
                })
            })
        });

        this._graphicsLayer.setZIndex(9999999);

    }

    updateStyle() {

        var content;
        for (var i = 0; i < this.content.length; i++) {

            content = this.content[i];

            for (var j = 0; j < content.layers.length; j++) {

                if (content.type.toLowerCase() === 'ogc' && content.serviceParameters && content.serviceParameters.format && content.serviceParameters.format.toLowerCase() === 'pbf') {

                    let style = this.createVectorLayerStyle(content.layers[j]);
                    content.layers[j].olLayer.setStyle(style);

                }

                if (content.layers[j].olLayer) {

                    content.layers[j].olLayer.setOpacity(content.layers[j].style.opacity);

                }

            }

        }

    }

    _createContentSchema() {

        this.containerElement = document.getElementById(this._target);
        this.mapElement = document.createElement('div');
        this.mapElement.className = 'gb gb-map';
        this.mapElement.id = this._elementId;
        this.containerElement.appendChild(this.mapElement);

        this._createDefaultPanels();
        this._createDefaultDocks();

    }

    _registerEvents() {

        let self = this;
        document.onkeydown = function (evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {

                self.closeAllTools();
                self.closeAllSubPanels();

            }
        };

    }

    _createLayers() {

        let content;
        for (let i = 0; i < this._content.length; i++) {
            content = this._content[i];
            this._createOlLayer(content, i);
        }

        this._setZIndex();

    }

    _createOlLayer(content, i) {

        let self = this;
        let olLayer;
        switch (content.type.toLowerCase()) {
            case 'baselayer':

                this._createBaseLayerOlLayer(content, i);
                break;

            case 'wms':

                // Tenta corrigir a url do source 
                content.source = content.source.replace('/wms', '');
                content.wmsSource = content.source + '/wms';

                if (content.wmsParameters && content.wmsParameters.tiled) {

                    content.olLayer = this._createTileWmsOlLayer(content);

                } else if (content.wmsParameters && content.wmsParameters.ungrouped) {

                    this._createTileWmsEOlLayer(content);

                } else {

                    content.olLayer = this._createImageWmsOlLayer(content);

                }

                if (content.auth) this._getLegendImages(content);

                break;

            case 'tilewms':

                content.wmsSource = content.source;
                content.olLayer = this._createTiledWmsOlLayer(content);
                this._getLegendImages(content);

                break;

            case 'ogc':

                content.defaultService = content.defaultService || 'wms';

                if (content.defaultService === 'wms') {

                    content.wmsSource = content.source + '/wms';

                    if (content.wmsParameters && content.wmsParameters.tiled) {

                        content.olLayer = this._createTileWmsOlLayer(content);

                    } else {

                        content.olLayer = this._createImageWmsOlLayer(content);

                    }

                } else if (content.defaultService === 'wfs') {

                    if (content.serviceParameters && content.serviceParameters.tiled) {


                        this._createTileWfsOlLayer(content);

                    } else {

                        this._createStaticWfsOlLayer(content);

                    }

                }
                break;

            case 'geojson':

                let vectorSource = new ol.source.Vector({
                    features: (new ol.format.GeoJSON()).readFeatures(content.layers[0], {
                        featureProjection: self.mapSRID
                    })
                });

                olLayer = new ol.layer.Vector({
                    source: vectorSource,
                    style: function (feature) {
                        return self._vectorStyles[feature.getGeometry().getType()];
                    }
                });

                content.olLayer = olLayer;
                break;

            case 'geowisescene':
                break;
            case 'arcgisimagelayer':
                let source = new ol.source.ImageArcGISRest({
                    url: content.source,
                    ratio: 1,
                    params: {
                        layers: 'show:' + content.layers.map(function (l) { return l.layer }).join(',')
                    }
                })
                content.olLayer = new ol.layer.Image({
                    source: source
                })
                break;
            default:
                throw 'Unknown layer type: ' + content.type;
        }

    }

    _getLegendImages(content) {

        for (let i = 0; i < content.layers.length; i++) {

            content.layers[i].legendImg = document.createElement('img');
            let legendUrl = this._buildWMSLegendURL(content.wmsSource, content.workspace, content.layers[i].layer);
            this.customImageLoadFunction(content.layers[i].legendImg, legendUrl, content.auth)

        }

    }

    _buildWMSLegendURL(source, workspace, layer) {

        return `${source}/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${workspace}:${layer}`;

    }

    _createBaseLayerOlLayer(content) {

        for (let j = 0; j < content.layers.length; j++) {

            let confLayer = content.layers[j];
            if (confLayer.type == 'XYZ') {

                let olLayer = new ol.layer.Tile({
                    source: new ol.source.XYZ({
                        url: confLayer.source,
                        crossOrigin: 'Anonymous',
                        imageLoadFunction: (img, src) => {
                            this.customImageLoadFunction(img, src, content.auth);
                        }
                    })
                });

                confLayer.olLayer = olLayer;
                confLayer.olLayer.setOpacity(confLayer.style ? confLayer.style.opacity : 1);

            } else if (confLayer.type == 'BingMaps') {

                let olLayer = new ol.layer.Tile({
                    visible: true,
                    preload: Infinity,
                    source: new ol.source.BingMaps({
                        key: confLayer.key,
                        imagerySet: confLayer.imagery,
                        maxZoom: 19
                    })
                });

                confLayer.olLayer = olLayer;
                confLayer.olLayer.setOpacity(confLayer.style ? confLayer.style.opacity : 1);
            }
        }
    }

    _createImageWmsOlLayer(content) {

        let wmsSubLayers = [];
        for (let j = 0; j < content.layers.length; j++) {

            if (content.layers[j].display) {
                wmsSubLayers.push(content.workspace + ':' + content.layers[j].layer);
            }

        }

        let params = content.wmsParameters || {};
        params.layers = wmsSubLayers;

        return new ol.layer.Image({
            source: new ol.source.ImageWMS({
                url: content.wmsSource || content.source,
                params: params,
                ratio: 1,
                serverType: 'geoserver',
                crossOrigin: 'anonymous',
                imageLoadFunction: (img, src) => {
                    this.customImageLoadFunction(img, src, content.auth);
                }
            })
        });

    }

    _createTileWmsEOlLayer(content) {



        for (let j = 0; j < content.layers.length; j++) {

            const params = content.wmsParameters || {};
            params.layers = content.workspace + ':' + content.layers[j].layer;

            let layer = content.layers[j];
            layer.olLayer = new ol.layer.Image({
                source: new ol.source.ImageWMS({
                    url: content.wmsSource || content.source,
                    params: {
                        layers: content.workspace + ':' + content.layers[j].layer
                    },
                    ratio: 1,
                    serverType: 'geoserver',
                    crossOrigin: 'anonymous',
                    imageLoadFunction: (img, src) => {
                        this.customImageLoadFunction(img, src, content.auth);
                    }
                })
            });

        }

    }


    _createTiledWmsOlLayer(content) {

        for (let j = 0; j < content.layers.length; j++) {

            let layer = content.layers[j];
            let olLayer = new ol.layer.Tile({
                source: new ol.source.XYZ({
                    url: layer.source,
                    crossOrigin: 'Anonymous',
                    imageLoadFunction: (img, src) => {
                        this.customImageLoadFunction(img, src, content.auth);
                    }
                })
            });

            layer.olLayer = olLayer;
            layer.olLayer.setOpacity(layer.style ? layer.style.opacity : 1);

        }

    }

    _createTileWmsOlLayer(content) {

        let wmsSubLayers = [];
        for (let j = 0; j < content.layers.length; j++) {

            if (content.layers[j].display) {
                wmsSubLayers.push(content.workspace + ':' + content.layers[j].layer);
            }
        }

        let params = content.wmsParameters;
        params.layers = wmsSubLayers;

        return new ol.layer.Tile({
            visible: true,
            preload: Infinity,
            source: new ol.source.TileWMS({
                url: content.wmsSource,
                params: params,
                serverType: 'geoserver',
                // Countries have transparency, so do not fade tiles:
                transition: 0,
                crossOrigin: 'anonymous',
                imageLoadFunction: (img, src) => {
                    this.customImageLoadFunction(img, src, content.auth);
                }
            })
        });
    }

    _createTileWfsOlLayer(content) {

        if (content.serviceParameters.format.toLowerCase() === 'pbf') {

            for (let j = 0; j < content.layers.length; j++) {

                let layer = content.layers[j];
                layer.olLayer = new ol.layer.VectorTile({
                    //declutter: true,
                    style: this.createVectorLayerStyle(layer),
                    source: new ol.source.VectorTile({
                        tilePixelRatio: 1, // oversampling when > 1
                        tileGrid: ol.tilegrid.createXYZ(),
                        format: new ol.format.MVT(),
                        url: content.source + '/gwc/service/tms/1.0.0/' + content.workspace + ':' + layer.layer +
                            '@' + content.serviceParameters.srs + '@pbf/{z}/{x}/{-y}.pbf'
                    })
                });
                layer.olLayer.setOpacity(+layer.style.opacity || 1);

            }
        }
    }

    _createStaticWfsOlLayer(content) {

        if (content.serviceParameters.format.toLowerCase() === 'geojson') {


            for (let j = 0; j < content.layers.length; j++) {

                let vectorSource = new ol.source.Vector({
                    url: content.source + '/ows?service=WFS&version=1.0.0&request=GetFeature&maxFeatures=5000&typeName=' + content.workspace + ':' + content.layers[j].layer + '&outputFormat=application%2Fjson&srsName=' + this.srid,
                    format: new ol.format.GeoJSON()
                });

                content.layers[j].olLayer = new ol.layer.Vector({
                    source: vectorSource
                });
            }



        }
    }

    createVectorLayerStyle(layer) {

        let olFill, olStroke, olImage, olCircle, olStyle, olText, olStyleFnc;
        let style = layer.style;


        switch (layer.primitive.toLowerCase()) {
            case 'polygon':

                olFill = new ol.style.Fill(style.fill);
                olStroke = new ol.style.Stroke(style.stroke);
                break;

            case 'line':

                olStroke = new ol.style.Stroke(style.stroke);
                break;

            case 'point':

                if (style.image) {

                    olImage = new ol.style.Icon({
                        anchor: style.image.anchor,
                        anchorXUnits: 'pixels',
                        anchorYUnits: 'pixels',
                        src: style.image.src
                    });

                } else if (style.circle) {

                    let circleStyle = {
                        radius: style.circle.radius,
                        fill: new ol.style.Fill(style.fill),
                        stroke: new ol.style.Stroke(style.stroke)
                    };
                    olCircle = new ol.style.Circle(circleStyle);

                }

                break;


            default:
                break;
        }


        olStyleFnc = function (feature) {

            // Graphic Style
            let olGraphicStyle = new ol.style.Style({
                fill: olFill,
                stroke: olStroke,
                image: olImage || olCircle
            });

            // Text Style
            let olTextStyle = null;
            if (style.label) {

                olTextStyle = new ol.style.Style();

                olTextStyle.setText(new ol.style.Text({
                    font: style.label.size + 'px ' + style.label.font || 'Arial 12px',
                    text: feature.get(style.label.text),
                    fill: new ol.style.Fill({ color: style.label.color || 'black' }),
                    stroke: new ol.style.Stroke({ color: 'white', width: 1 }),
                    placement: style.label.placement || 'point',
                    // overflow: false,
                    //textBaseline: 'middle',
                    // textAlign: 'center',
                    // offsetX: 0,
                    // offsetY: 0
                }));
            }

            return olTextStyle ? [olGraphicStyle, olTextStyle] : olGraphicStyle;

        };

        return olStyleFnc;

    }

    _drawLayers() {

        this._updateLayersParms();

        let content;
        let confLayer;

        for (let i = 0; i < this._content.length; i++) {

            content = this._content[i];
            if (content.display) {

                if (content.olLayer) {

                    this.ol.addLayer(content.olLayer);

                } else {

                    for (let j = 0; j < content.layers.length; j++) {
                        confLayer = content.layers[j];
                        if (confLayer.display && confLayer.olLayer) {
                            this.ol.addLayer(confLayer.olLayer);
                        }
                    }
                }
            }
        }

        this.eventDispatcher('draw');

    }

    _setZIndex() {

        let content;
        let confLayer;
        let zIndexCount = 0;

        for (let i = this._content.length - 1; i >= 0; i--) {
            content = this._content[i];
            if (content.olLayer) {
                content.olLayer.setZIndex(++zIndexCount);
            } else {
                for (let j = content.layers.length - 1; j >= 0; j--) {
                    confLayer = content.layers[j];
                    if (confLayer.olLayer) {
                        confLayer.olLayer.setZIndex(++zIndexCount);
                    }
                }
            }
        }
    }

    _removeAllLayers() {

        let content;
        let confLayer;

        for (let i = this._content.length - 1; i >= 0; i--) {
            content = this._content[i];
            if (content.olLayer) {
                this.ol.removeLayer(content.olLayer);
            } else {
                for (let j = content.layers.length - 1; j >= 0; j--) {
                    confLayer = content.layers[j];
                    if (confLayer.olLayer) {
                        this.ol.removeLayer(confLayer.olLayer);
                    }
                }
            }
        }

        this.eventDispatcher('removeLayers');
    }

    _updateLayersParms() {

        for (let i = 0; i < this._content.length; i++) {

            let content = this._content[i];

            if (content.type !== 'vector' && content.display && content.olLayer) {

                let wmsSubLayers = [];

                for (var k = 0; k < content.layers.length; k++) {
                    if (content.layers[k].display) {
                        wmsSubLayers.push(content.workspace + ':' + content.layers[k].layer);
                    }
                }

                if (wmsSubLayers.length > 0) {
                    wmsSubLayers = wmsSubLayers.reverse();
                    content.olLayer.getSource().updateParams({ layers: wmsSubLayers });
                } else {
                    content.display = false;
                }

            }
        }

    }

    _createDefaultPanels() {

        let keys = Object.keys(this._panelPosition);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let panel = new GeoBuilder.Panel({
                position: this._panelPosition[key]
            });
            panel.map = this;
        }

    }

    getPanel(position) {

        for (let i = 0; i < this._panels.length; i++) {
            if (this._panels[i].position == position) {
                return this._panels[i];
            }
        }
    }

    _createDock(cssClass) {

        if (this._docks.indexOf(cssClass) > -1) {
            throw 'Dock já adicionado.';
        } else {
            this._docks.push(cssClass);
        }

        let dockElement = document.createElement('div');
        dockElement.className = cssClass;
        dockElement.id = `${cssClass}_${this._target}`;
        document.getElementById(this._target).append(dockElement);

    }

    _createDefaultDocks() {

        this._createDock('gb-dock-bottom');
        this._createDock('gb-dock-right');
        this._createDock('gb-dock-left');

    }

    addControl(control, subPanel) {

        control.map = this;
        control.initialize();

        if (control instanceof GeoBuilder.GeoSubPanel) {

            control.createSubPanelElement();
            control.registerControls();

        } else if (control instanceof GeoBuilder.GeoButton) {

            this._buttons.push(control);

        } else if (control instanceof GeoBuilder.GeoTool) {

            this._tools.push(control);

        }

        if (control._hasUI != false) {

            this._registerControlUIElem(control, subPanel);

        }

        control.eventDispatcher('ready');

    }

    _registerControlUIElem(control, subPanel) {

        let targetElementId = '';

        // Define em qual panel ou subpanel o controle será adicionado
        if (!subPanel) {

            let postion = control.config.position || this._panelPosition.LEFT_TOP;
            control.panel = this.getPanel(postion);
            targetElementId = control.panel.elementId;

        } else {

            let hasPanel = false;
            control.panel = subPanel;
            targetElementId = subPanel;

            for (let i = 0; i < this._subPanels.length; i++) {
                if (this._subPanels[i].subPanelElementId == control.panel) {
                    hasPanel = true;
                }
            }

            if (!hasPanel) {
                throw 'SubPainel inexistente.';
            }

        }

        let controlElement = document.createElement('div');
        control.element = controlElement;
        controlElement.className = `${control.config.class} gb-control`;
        controlElement.id = `${targetElementId}_control_${control.id}`;
        document.getElementById(targetElementId).append(controlElement);

        if (control.config.tip) {

            let tipElement = null;

            controlElement.addEventListener('mouseover', function () {

                let top = 0;
                let left = 0;
                let right = 0;
                let onLeft = controlElement.parentElement.offsetLeft < (document.getElementById(control.map.target).offsetWidth / 2);

                if (!control.parent) { // On Panel

                    tipElement = document.getElementById(`${control.panel.elementId}-tooltip`);
                    top = controlElement.parentElement.offsetTop + controlElement.offsetTop;
                    left = controlElement.parentElement.offsetLeft + controlElement.clientWidth;
                    right = control.map.clientSize - controlElement.parentElement.offsetLeft;

                } else if (control.parent) { // On SubPanel

                    let subPanelElement = document.getElementById(control.parent.subPanelElementId);
                    tipElement = document.getElementById(`${control.parent.panel.elementId}-tooltip`);
                    top = subPanelElement.offsetTop;
                    left = subPanelElement.clientWidth + subPanelElement.offsetLeft;
                    right = control.map.clientSize - subPanelElement.offsetLeft;

                }

                if (!control.isOpen) {

                    tipElement.innerHTML = control.config.tip;
                    tipElement.style.display = 'block';
                    tipElement.style.top = top + 'px';

                    if (onLeft) {

                        tipElement.style.left = left + 'px';
                        tipElement.style.right = 'auto';

                    } else {

                        tipElement.style.right = right + 'px';
                        tipElement.style.left = 'auto';

                    }
                }

                controlElement.addEventListener('click', function () {
                    tipElement.style.display = 'none';
                });

            });

            controlElement.addEventListener('mouseout', function () {
                tipElement.style.display = 'none';
            });

        }

        if (control instanceof GeoBuilder.GeoSubPanel) {

            control.elementId = controlElement.id;

            controlElement.addEventListener('click', function () {
                control.click();
            });

            let markerElement = document.createElement('div');
            markerElement.className = `gb-control-marker-${control.config.position || control.config.panel}`;
            controlElement.append(markerElement);


        } else if (control instanceof GeoBuilder.GeoButton) {

            controlElement.addEventListener('click', function () {
                control.click();
            });

        } else if (control instanceof GeoBuilder.GeoTool) {

            control.controlElement = controlElement;

            controlElement.addEventListener('click', function () {

                control.map.closeAllSubPanels();
                //control.map.closeAllTools();

                if (control.isActive) {

                    control.deactivate();
                    controlElement.classList.remove('gb-control-active');

                } else {

                    control.activate();
                    controlElement.classList.add('gb-control-active');

                    if (control.parent) {

                        let parentControl = document.getElementById(control.parent.elementId);
                        parentControl.className = controlElement.className;
                        control.parent.isOpen = true;
                        control.parent.isActive = true;

                    }

                    control.eventDispatcher('activate');
                }

            });

            if (control instanceof GeoBuilder.GeoWidget) {

                control.createWidget();

            }
        }
    }

    closeAllTools() {

        for (let i = 0; i < this._tools.length; i++) {

            if (this._tools[i].isActive) {
                this._tools[i].deactivate();
                this._tools[i].isActive = false;
                this._tools[i].controlElement.classList.remove('gb-control-active');
            }
        }

    }

    closeAllSubPanels() {

        for (let i = 0; i < this._subPanels.length; i++) {
            this._subPanels[i].deactivate();
        }

    }

    openDock(position, width) {



        if (!this._dockIsOpen[position]) {

            let dockId = `gb-dock-${position}_${this._target}`;
            let dockElement = document.getElementById(dockId);

            let dockWidth = 0;
            if (!width) {

                dockWidth = this.clientSize * 0.2 < 350 ? 350 : this.clientSize * 0.2;
                dockElement.style.width = `${dockWidth / this.clientSize * 100}%`;
                this.mapElement.style.width = `${(this.clientSize - dockWidth) / this.clientSize * 100}%`;

            } else {


                dockElement.style.width = `${width}%`;
                this.mapElement.style.width = `${100 - width}%`;

            }

            this.mapElement.style.position = 'absolute';
            this.ol.updateSize();

            if (position == 'right') {

                //this.mapElement.style.left = '0';


            } else if (position == 'left') {

                //this.mapElement.style.right = '0';

            }

            this._dockIsOpen[position] = true;

            return true;
        }

        return false;

    }

    getDock(position) {

        let dockId = `gb-dock-${position}_${this._target}`;
        return document.getElementById(dockId);

    }

    closeDock(position) {

        //if (position == 'right') {

        let dockId = `gb-dock-${position}_${this.target}`;
        let dockElement = document.getElementById(dockId);
        let mapElement = this.mapElement;
        dockElement.style.width = `0px`;
        mapElement.style.width = `100%`;
        mapElement.style.right = `auto`;
        mapElement.style.left = `auto`;

        this.ol.updateSize();


        // }

        for (let i = 0; i < this._panels.length; i++) {
            this._panels[i]._restoreDefaultStyle();
        }

        this._dockIsOpen[position] = false;

    }

    unDockWidgetFrom(position) {

        for (let i = 0; i < this._tools.length; i++) {

            if (this._tools[i].onDock && this._tools[i].onDock == position) {
                this._tools[i].removeFromDock(position);
            }

        }
    }

    getMapAsDataURL() {

        var mapCanvas = document.createElement('canvas');
        var size = this.ol.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        var mapContext = mapCanvas.getContext('2d');
        Array.prototype.forEach.call(
            document.querySelectorAll(`#${this.target} canvas:not(.ol-overviewmap-map canvas)`),
            function (canvas) {
                if (canvas.width > 0) {
                    var opacity = canvas.parentNode.style.opacity;
                    mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                    var transform = canvas.style.transform;
                    var matrix = transform
                        .match(/^matrix\(([^\(]*)\)$/)[1]
                        .split(',')
                        .map(Number);
                    CanvasRenderingContext2D.prototype.setTransform.apply(
                        mapContext,
                        matrix
                    );
                    mapContext.drawImage(canvas, 0, 0);
                }
            }
        );
        return mapCanvas.toDataURL();

    }

    getLegend() {

        var legend = '';
        var content;
        var wmsSubLayers;

        for (var i = 0; i < this.content.length; i++) {

            content = this.content[i];
            if (content.display) {

                if (content.type.toLowerCase() === 'ogc' || content.type.toLowerCase() === 'wms') {
                    wmsSubLayers = [];
                    for (var j = 0; j < content.layers.length; j++) {

                        let url = content.wmsSource || content.source + '/wms';

                        if (content.layers[j].display) {

                            var symbol = '';

                            if (content.layers[j].legendImg) {

                                symbol = `<img class="img-legend-print" src="${content.layers[j].legendImg.src}"/>  `

                            } else {

                                symbol = '<img class="img-legend-print" src="' + (url + '/?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=' +
                                    content.workspace + ':' + content.layers[j].layer) + '"/>';

                            }

                            wmsSubLayers.push('<div style="display: flex; width: max-content;"><div style="width: 20px; overflow: hidden; float: left">' + symbol + '</div><div>' + content.layers[j].name + '</div></div>');

                        }
                    }

                    legend += wmsSubLayers.join('');
                }
            }
        }

        return legend;

    }

    _getDotsPerMapUnit() {

        let INCHES_PER_UNIT = {
            'm': 39.37,
            'dd': 4374754
        };

        let div = document.createElement('div');
        div.style.height = '1in';
        div.style.width = '1in';
        div.style.top = '-100%';
        div.style.left = '-100%';
        div.style.position = 'absolute';
        document.body.appendChild(div);
        let dpi = div.offsetHeight;
        document.body.removeChild(div);

        let unit = this._map2D.getView().getProjection().getUnits();

        return (INCHES_PER_UNIT[unit] * dpi);

    }

    getResolutionFromScale(scaleDenominator) {

        return parseInt(scaleDenominator) / (this._getDotsPerMapUnit());

    }

    getScaleDenominator() {

        let constant = this._getDotsPerMapUnit();
        let resolution = this._map2D.getView().getResolution();

        return Math.round(resolution * constant);

    }

    setScaleDenominator(scaleDenominator) {

        let resolution = this.getResolutionFromScale(scaleDenominator);
        this._map2D.getView().setResolution(resolution);

    }

    updateSize() {

        this._map2D.updateSize();

    }

    _saveDefaultStyle() {

        this._defaultStyle = Object.assign({}, this.mapElement.style);

    }

    _restoreDefaultStyle() {

        this.mapElement.style = this._defaultStyle;

    }

    _jsonToGeometry(json) {

        return (new ol.format.GeoJSON()).readGeometry(json);

    }

    _wktToGeometry(json) {

        return (new ol.format.WKT()).readGeometry(json);

    }

    _fitGeometry(geometryOrExtent, zoomPercentage) {

        const mapView = this._map2D.getView();
        const mapSize = this._map2D.getSize();
        const paddingX = mapSize[0] * zoomPercentage;
        const paddingY = mapSize[1] * zoomPercentage;

        if (Array.isArray(geometryOrExtent)) {
            mapView.fit(geometryOrExtent, { constrainResolution: false, padding: [paddingY, paddingX, paddingY, paddingX] });
        } else {
            if (geometryOrExtent.getCoordinates().length > 0) {
                mapView.fit(geometryOrExtent.getExtent(), { constrainResolution: false, padding: [paddingY, paddingX, paddingY, paddingX] });
            }
        }

        const minResolution = mapView.getMinResolution();
        const currentResolution = mapView.getResolution();

        if (currentResolution < minResolution) {
            mapView.setResolution(minResolution);
        }

    }

    toOlGeom(geometry, format) {

        let geom = null;

        if (format === 'geojson') {
            geom = this._jsonToGeometry(geometry);
        } else if (format === 'wkt') {
            geom = this._wktToGeometry(geometry);
        } else if (format === 'openlayers') {
            return geometry;
        } else {
            throw (`Formato não suportado "${format}".`);
        }

        return geom;

    }

    fitTo(geometry, format, zoomPercentage = 0.05) {

        let geom = this.toOlGeom(geometry, format);
        this._fitGeometry(geom, zoomPercentage);

    }

    fitToDrawingGeometries(zoomPercentage = 0.05) {

        const source = this._graphicsLayer.getSource();
        const featureCount = source.getFeatures().length;

        if (featureCount > 0) {
            const geometry = source.getExtent();
            this.fitTo(geometry, 'openlayers', zoomPercentage);
        }

    }

    drawGeometry(geometry, format, options = {}) {

        if (!geometry) {
            return;
        }

        const fitToGeometry = options.fitToGeometry || false;
        const zoomPercentage = typeof options.zoomPercentage === 'undefined' ? 0.05 : options.zoomPercentage;

        let geom = this.toOlGeom(geometry, format);
        geom.transform(options.srid || this.ol.getView().getProjection().getCode(), this.ol.getView().getProjection().getCode());

        var feature = new ol.Feature({
            geometry: geom
        });

        if (options.style) {
            feature.setStyle(options.style);
        }

        this._graphicsLayer.getSource().addFeature(feature);

        if (fitToGeometry) {
            this._fitGeometry(geom, zoomPercentage);
        }

    }

    clearDrawingGeometries() {

        this._graphicsLayer.getSource().clear();

    }

    notify(message, config) {

        config = config || {};

        let msgElementId = `gb_float_msg_${this.target}`;

        let msgElement = document.createElement('div');
        msgElement.className = 'gb gb-float-dlg';
        msgElement.id = msgElementId;
        msgElement.innerHTML = message;


        let mapElement = document.getElementById(this.target);

        mapElement.appendChild(msgElement);
        document.getElementById(msgElementId).style.display = 'block';

        // Events
        window.setTimeout(function () {

            mapElement.removeChild(msgElement);

        }, config.time || 5000);

    }

    eventDispatcher(eventName, data) {

        this._verifyEventName(eventName);

        for (let i = 0; i < this._events[eventName].length; i++) {

            this._events[eventName][i](data);

        }

    }

    on(eventName, callback) {

        this._verifyEventName(eventName);
        this._events[eventName].push(callback);

    }

    un(eventName, callback) {

        this._verifyEventName(eventName);
        this._events[eventName] = this._events[eventName].filter(item => item !== callback);

    }
    _verifyEventName(eventName) {

        if (Object.keys(this._events).indexOf(eventName) === -1) {
            throw ('Event does not exist: "' + eventName + '".');
        }

    }

    setTarget(target) {

        if (typeof target === 'string') {
            target = document.getElementById(target);
        }
        target.appendChild(this.containerElement);

    }

    hideDisabledControls() {

        // Tools
        for (let i = 0; i < this._tools.length; i++) {
            let tool = this._tools[i];
            if (!tool.isActive && tool.controlElement) {
                tool.controlElement.style.display = 'none';
            }
        }

        // Buttons
        for (let i = 0; i < this._buttons.length; i++) {
            let buttom = this._buttons[i];
            if (!buttom.isActive && buttom.element) {
                buttom.element.style.display = 'none';
            }
        }

        // subpanels
        for (let i = 0; i < this._subPanels.length; i++) {
            let subpanel = this._subPanels[i];
            subpanel.deactivate();
            if (!subpanel.isActive && subpanel.element) {
                subpanel.element.style.display = 'none';
            }
        }

    }

    hideAllControls() {

        // Tools
        for (let i = 0; i < this._tools.length; i++) {
            let tool = this._tools[i];
            if (tool.controlElement) {
                tool.controlElement.style.display = 'none';
            }
        }

        // Buttons
        for (let i = 0; i < this._buttons.length; i++) {
            let buttom = this._buttons[i];
            if (buttom.element) {
                buttom.element.style.display = 'none';
            }
        }

        // subpanels
        for (let i = 0; i < this._subPanels.length; i++) {
            let subpanel = this._subPanels[i];
            subpanel.deactivate();
            if (subpanel.element) {
                subpanel.element.style.display = 'none';
            }
        }

    }

    showDisabledControls() {

        // Tools
        for (let i = 0; i < this._tools.length; i++) {
            let tool = this._tools[i];
            if (tool.controlElement) {
                tool.controlElement.style.display = 'block';
            }
        }

        // Buttons
        for (let i = 0; i < this._buttons.length; i++) {
            let buttom = this._buttons[i];
            if (buttom.element) {
                buttom.element.style.display = 'block';
            }
        }

        // subpanels
        for (let i = 0; i < this._subPanels.length; i++) {
            let subpanel = this._subPanels[i];
            if (subpanel.element) {
                subpanel.element.style.display = 'block';
            }
        }

    }

    getModifiedStyles() {

        let contentStyle = [];
        let content;
        for (var i = 0; i < this.content.length; i++) {

            content = this.content[i];

            for (var j = 0; j < content.layers.length; j++) {

                if (content.layers[j].modifiedStyle) {

                    contentStyle.push({

                        id: content.layers[j].id,
                        style: content.layers[j].style

                    });

                }
            }
        }

        return contentStyle;

    }

    customImageLoadFunction(img, src, auth) {

        var oReq = new XMLHttpRequest();
        oReq.open("GET", src, true);
        oReq.responseType = "blob";

        if (auth) {
            oReq.setRequestHeader('Authorization', auth);
        }

        oReq.onload = function () {

            var urlCreator = window.URL || window.webkitURL;
            var blob = oReq.response;
            var imageUrl = urlCreator.createObjectURL(blob);

            if (img.getImage) {
                img.getImage().src = imageUrl;
            } else {
                img.src = imageUrl;
            }

        };

        oReq.send();
    }

    customVectorLoadFunction(vectorSource, url, auth) {

        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        if (auth) {
            xhr.setRequestHeader('Authorization', auth);
        }
        xhr.onload = function () {
            if (xhr.status == 200) {
                vectorSource.addFeatures(
                    vectorSource.getFormat().readFeatures(xhr.responseText));
            }
        }
        xhr.send();
    }

}

const UnitsCoversion = {
    m: {
        km: 1 / 1000,
        mi: 1 / 1609.344,
        m: 1
    },
    km: {
        m: 1000,
        mi: 1 / 1.609344,
        km: 1
    },
    m2: {
        ha: 1 / 1e-4,
        km2: 1e-6,
        acre: 1 / 4046.856,
        m2: 1,
        sf: 10.7639
    },
    dd: {
        dd: 1
    },
    sf: {
        m2: 0.092903,
        sf: 1
    }
};

export { GeoMap, UnitsCoversion };