import { GeoWidget } from '../core/GeoWidget';

class ImportGeom extends GeoWidget {

    constructor(config) {
        config = config || {};
        config.tip = config.tip || 'Importar Geometria';
        config.title = config.title || 'Importar Geometria';
        config.class = config.class || 'gb-import-geo';
        config.docked = config.docked || false;
        config.minWidth = config.minWidth || '500px';
        config.minHeight = config.minHeight || '400';
        config.maxWidth = config.maxWidth || '400';
        config.maxHeight = config.maxHeight || '600';
        config.sridOptions = config.sridOptions || [
          { value:"EPSG:4326"  ,description:"SIRGAS 2000 (latitude, longitude)", selected: true},
          { value:"EPSG:3857"  ,description:"WGS-84 Pseudo-Mercartor (X,Y)"},
          { value:"EPSG:31981" ,description:"SIRGAS 2000 UTM 21S (E, N)"},
          { value:"EPSG:31982" ,description:"SIRGAS 2000 UTM 22S (E, N)"},
          { value:"EPSG:31983" ,description:"SIRGAS 2000 UTM 23S (E, N)"},
        ];
        super(config);

        this._inputGeo = null;
        this._btnAddGeo = null;
        this._inputFile = null;
        this.ui = this._builUi();

    }

    initialize() {
        this.on('ready', () => {
            this._getElements();
            this._registerEvents();
        });
    }

    _builUi() {

        return `
        <div class="p-3">
            <div class="row"> 
                <div class="col" style="text-align: justify;">
                    Utilize esta ferramenta para visualizar uma geometria sobre o mapa. Os formatos de arquivos suportados são: ESRI Shape File (enviar os arquivos .shp, .shx, .prj e .dbf compactados em um arquivo .zip), .kml, .kmz e .dxf.
                </div>
            </div>
            <br>
            <div class="form-group" >
                <label>Selecione a Referência Geográfica do Arquivo</label>
                <select id="gb-ig-src-${this.id}" class="form-control">
                  ${this.config.sridOptions.map(option => {
                    if (option.selected) {
                      return `<option value="${option.value}" selected>${option.description}</option>`
                    } else {
                      return `<option value="${option.value}">${option.description}</option>`
                    }
                  }).join('\n')}
                </select>   
            </div>
            <div class="form-group">
                <label>Arquivo</label>
                <div class="row">
                    <div class="col">
                        <div class="input-group">
                            <span class="input-group-btn">
                                <span class="btn btn-primary" onclick="$(this).parent().find('input[type=file]').click();" id='gb-ig${this.id}-arquivo'>Buscar</span>
                                <input id="gb-upload-file${this.id}" name="uploaded_file" onchange="$(this).parent().parent().find('.form-control').html($(this).val().split(/[\\|/]/).pop());" style="display: none;" type="file" accept=".zip, .dxf, .kml, .kmz">
                                </span>
                            <span class="form-control"></span>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col">
                        <a class="btn btn-link float-left" href="#" role="button" id='gb-ig${this.id}-limpar'>Limpar</a>
                    </div>
                    <div class="col">
                        <a class="btn btn-success float-right" href="#" role="button" id='gb-ig${this.id}-enviar'>Enviar</a>
                    </div>
                </div>
            </div>
        </div>`;
    }


    _getElements() {
        this._inputGeo = document.getElementById(`gb-ig-src-${this.id}`);
        this._btnAddGeo = document.getElementById(`gb-ig${this.id}-enviar`);
        this._btnClear = document.getElementById(`gb-ig${this.id}-limpar`);
        this._inputFile = document.getElementById(`gb-upload-file${this.id}`);
    }

    _registerEvents() {

        this._btnAddGeo.addEventListener('click', () => {
            this.sendFile();
        });

        this._btnClear.addEventListener('click', () => {
            this._inputFile.files = null;
            this.map.clearDrawingGeometries();
        });

    }

    sendFile() {
        let reference = this._inputGeo.value;
        let file = document.getElementById(`gb-upload-file${this.id}`).files[0];
        let url = this.config.url.substr(-1) == '/' ? this.config.url.substr(0, this.config.url.length - 1) : this.config.url;

        if (reference && file) {

            var formData = new FormData();
            formData.append('reference', reference);
            formData.append('file', file);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `${url}/geowise/mapa/import_geo_file`, true);
            xhr.send(formData);

            xhr.onreadystatechange = () => {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    let data = JSON.parse(xhr.responseText);
                    if (data) {

                        if (!data.status) {
                            new MessageBox({
                                title: 'Falha',
                                message: data.message,
                                type: 'danger'
                            }).show();
                            return;
                        }

                        this._drawGeom(data);

                        new Notify({
                            message: 'Geometria importada com sucesso.',
                            type: 'success',
                            timeout: 5
                        }).show();

                    } else {

                        new MessageBox({
                            title: 'Falha',
                            message: 'Ocorreu um erro, tente enviar outro arquivo.',
                            type: 'danger'
                        }).show();
                    }
                }
            }
        } else {

            new MessageBox({
                title: 'Falha',
                message: 'Selecione o arquivo e a referência geográfica.',
                type: 'danger'
            }).show();
        }
    }

    _drawGeom(data) {

        var geoFormat = new ol.format.GeoJSON();
        var features = geoFormat.readFeatures(data.geo, {
            dataProjection: 'EPSG:4326',
            featureProjection: this.map.ol.getView().getProjection().getCode()
        });

        features.forEach((feature) => {
            this.map.drawGeometry(feature.getGeometry(), 'openlayers', {
                style: new ol.style.Style({
                    fill: new ol.style.Fill({
                        color: 'rgba(255, 204, 51, 0.2)'
                    }),
                    stroke: new ol.style.Stroke({
                        color: '#ffcc33',
                        width: 2
                    }),
                    image: new ol.style.Circle({
                        radius: 7,
                        fill: new ol.style.Fill({
                            color: '#ffcc33'
                        })
                    })
                })
            });
        });

        this.map.fitToDrawingGeometries();

    }

    activate() {
        super.activate();
        this.show();
    }

    deactivate() {
        this.hide();
        super.deactivate();
    }

}

export { ImportGeom };
